import taskRow from '@/templates/projects/_taskRow.js'
import cardAction from '@/templates/projects/_cardAction.js'
import listChip from '@/templates/cards/_listChip.js'

export default class {
  static progressColors(xAxis) {
    if (xAxis >= 100) {
      return {
        backgroundColor: $tpu.styles.completedColor(),
        color: "#fff"
      }
    }

    if (xAxis >= 50) {
      return {
        backgroundColor: $tpu.styles.positiveColor(),
        color: "#fff"
      }
    }

    return null
  }

  static scopeHeaderSpec(project, scopeCard) {
    const leftViews = []
    if (scopeCard.shapeUp.list) {
      leftViews.push(listChip.spec(scopeCard))
    }

    const centerViews = []
    if (scopeCard.desc) {
      centerViews.push(
        {
          "view": "icon",
          "padding": { top: 2, right: 4 },
          "material": {
            "name": $tpu.keys.icnDescription(),
            "size": 18
          },
        }
      )
    }

    // Hide it for now. Not sure if this feature is well-integrated with other features.
    // const riskLevel = scopeCard.shapeUp.riskLevel
    // if (riskLevel > 0) {
    //   centerViews.push(
    //     {
    //       "view": "icon",
    //       "padding": { top: 2, right: 4 },
    //       "material": {
    //         "name": $tpu.keys.icnsRiskLevel()[riskLevel],
    //         "size": 18
    //       },
    //     }
    //   )
    // }

    const rightViews = [
      {
        "view": "panels/horizontal",
        "height": "matchParent",
        // "backgroundColor": "#ff0000",
        "align": "middle",
        "childViews": [
          Object.assign({
            "view": "chip",
            "styleClasses": $tpu.styles.chipClasses(),
            "text": `${Math.round(scopeCard.shapeUp.hillPointX)}%`,
            "onClick": {
              "action": "panels/scrollTo",
              "viewId": "hill_chart_container",
              "animate": true,
              "onScroll": {
                "action": "commands/custom",
                "name": "scopes/focus",
                "scopeCardId": scopeCard.id
              }
            }
            // "onClick": this.listSelectAction(card)
          }, this.progressColors(scopeCard.shapeUp.hillPointX))
          // {
          //   "view": "label",
          //   "text": `${Math.round(scopeCard.shapeUp.hillPointX)}%`
          // }
        ]
      }
    ]


    return {
      "styleClasses": ["list-header", "no-hover"],
      "onClick": cardAction.spec(scopeCard, "scope"),
      "childViews": [
        {
          "view": "panels/split",
          "width": "matchParent",
          "padding": { top: 6 },
          "center": {
            "height": "matchParent",
            "childViews": [
              {
                "view": "panels/horizontal",
                "height": "matchParent",
                // "backgroundColor": "#ff0000",
                "align": "middle",
                "childViews": [
                  {
                    "view": "h4",
                    "padding": { "left": 2 },
                    "width": "matchParent",
                    "text": scopeCard.name,
                  }
                ]
              }
            ]
          },
          "right": {
            "height": "matchParent",
            "childViews": [
              cardAction.actionIconSpec(scopeCard, "scope")
            ]
          },
        },
        {
          "view": "panels/split",
          "width": "matchParent",
          "padding": { top: 2, bottom: 4 },
          "left": {
            "height": "matchParent",
            "childViews": leftViews
          },
          "center": {
            "height": "matchParent",
            "padding": { "left": 8, "right": 4 },
            "childViews": centerViews
          },
          "right": {
            "height": "matchParent",
            "childViews": rightViews
          },
        }
      ]
    }
  }

  static scopeFooterSpec(scopeCard) {
    const elementId = `${$tpu.keys.toHtmScopeId(scopeCard.id)}_new_task`
    return {
      "padding": { top: 8, bottom: 6 },
      "childViews": [
        {
          "view": "panels/form",
          "width": "matchParent",
          "childViews": [
            {
              "view": "fields/text",
              "styleClasses": ["outlined", "compact"],
              "width": "matchParent",
              "name": "name",
              "label": "Add a task",
              "placeholder": "Task name",
              "id": elementId
            }
          ],
          "onSubmit": {
            "action": "commands/custom",
            "name": "tasks/create",
            "properties": {
              // "onlyRefreshBoard": true,
              "scopeCardId": scopeCard.id
            },
            "onSuccess": {
              "action": "fields/reset",
              "targetId": elementId,
              "onReset": {
                "action": "fields/focus",
                "targetId": elementId,
              }
            }
          },
        }
      ]
    }
  }

  static spec(project, scopeCard, taskCards) {
    const rowSpecs = taskCards.map((taskCard) => {
      return taskRow.spec(taskCard)
    })
    return {
      "view": "panels/list",
      "id": $tpu.keys.toHtmScopeId(scopeCard.id),
      "styleClasses": ["card", "scope"],
      "backgroundColor": "#ebecf0",
      "width": $tpu.globals.isAttachmentView ? "matchParent" : 280,
      "padding": { left: 10, right: 10 },
      "sections": [
        {
          "header": project ? this.scopeHeaderSpec(project, scopeCard) : this.attachmentHeader(taskCards),
          "rows": rowSpecs,
          "footer": this.scopeFooterSpec(scopeCard)
        }
      ],
      "dragSupport": {
        "groupId": "scope",
        "onDrop": {
          "action": "commands/custom",
          "name": "tasks/move",
        },
        "paramNameForRowId": "taskId",
        "paramNameForNewListId": "newScopeId",
        "paramNameForNewAbsoluteIndex": "newIndex"
      }
    };
  }

  static attachmentHeader(taskCards) {
    return {
      "childViews": [
        {
          "view": "panels/split",
          "width": "matchParent",
          "padding": { top: 4 },
          "left": {
            "height": "matchParent",
            "childViews": [
              {
                "view": "panels/horizontal",
                "height": "matchParent",
                "align": "middle",
                "childViews": [
                  {
                    "view": "label",
                    "text": `${taskCards.length} child tasks`,
                  }
                ]
              }
            ]
          },
          "right": {
            // "height": "matchParent",
            "childViews": [
              {
                "view": "button",
                // "styleClasses": ["icon"],
                "styleClasses": $tpu.styles.iconButtonClasses(),
                "icon": {
                  "material": {
                    "name": $tpu.keys.icnRefresh()
                  }
                },
                "onClick": {
                  "action": "windows/reload"
                }
              }
            ]
          }
        }
      ]
    }

    // return {
    //   "view": "spacer",
    //   "height": 4
    // }
  }
}
